<template>
  <div>
    <el-form :model="detailData" label-suffix=":" label-width="240px">
      <div class="title">1.发票信息</div>
      <template v-if="detailData">
        <!-- <el-form-item label="增值税一般纳税人" :rules="[{ required: true }]">
          <span :class="{ highLight: detailData.taxNatureLight }">
            {{ detailData.taxNature == 1 ? "是" : "否" }}
          </span>
        </el-form-item>
        <el-form-item label="发票抬头" :rules="[{ required: true }]">
          <span :class="{ highLight: detailData.invoiceLight }">
            {{ detailData.invoice }}
          </span>
        </el-form-item>
        <el-form-item label="纳税人识别号" :rules="[{ required: true }]">
          <span :class="{ highLight: detailData.taxIdLight }">
            {{ detailData.taxId }}
          </span>
        </el-form-item> -->
        <el-form-item label="企业详细地址" :rules="[{ required: true }]">
          <span
            :class="{ highLight: detailData.postInfoVo.detailAddressLight }"
          >
            <!-- {{ detailData.taxAddress }} -->
            {{ detailData.postInfoVo.detailAddress }}
          </span>
        </el-form-item>
        <!-- <el-form-item label="注册电话" :rules="[{ required: true }]">
          <span :class="{ highLight: detailData.registerTelLight }">
            {{ detailData.registerTel }}
          </span>
        </el-form-item>
        <el-form-item label="开户行" :rules="[{ required: true }]">
          <span :class="{ highLight: detailData.bankLight }">
            {{ detailData.bank }}
          </span>
        </el-form-item>
        <el-form-item label="银行账号" :rules="[{ required: true }]">
          <span :class="{ highLight: detailData.bankAccountLight }">
            {{ detailData.bankAccount }}
          </span>
        </el-form-item> -->
      </template>
      <div class="title">2.接收信息</div>
      <template v-if="detailData.postInfoVo">
        <!-- <el-form-item label="发票邮寄地址" :rules="[{ required: true }]">
          <span
            :class="{ highLight: detailData.postInfoVo.detailAddressLight }"
          >
            {{ detailData.postInfoVo.detailAddress }}
          </span>
        </el-form-item> -->
        <el-form-item label="电邮地址" :rules="[{ required: true }]">
          <span :class="{ highLight: detailData.postInfoVo.emailLight }">
            {{ detailData.postInfoVo.email }}
          </span>
        </el-form-item>
        <el-form-item label="联系人姓名" :rules="[{ required: true }]">
          <span :class="{ highLight: detailData.postInfoVo.nameLight }">
            {{ detailData.postInfoVo.name }}
          </span>
        </el-form-item>
        <el-form-item label="联系人手机号" :rules="[{ required: true }]">
          <span :class="{ highLight: detailData.postInfoVo.phoneNumberLight }">
            {{ detailData.postInfoVo.phoneNumber }}
          </span>
        </el-form-item>
        <!-- <el-form-item label="联系人电子邮件" :rules="[{ required: true }]">
          <span :class="{ highLight: detailData.postInfoVo.emailLight }">
            {{ detailData.postInfoVo.email }}
          </span>
        </el-form-item> -->
      </template>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    detailData: {
      type: Object,
      default() {
        return { postInfoVo: {}, invoice: {} };
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}
.myCopy {
  font-size: 20px;
  margin-left: 5px;
  cursor: pointer;
}
.downloadArea {
  text-align: center;
}
</style>
