import request from "@/config/serve.js";

// 查询
export function getInfoList(params) {
  return request("post", "/developerPaid/infoList", params);
}
// 查询详情
export function getInfoDetailDiff(params) {
  return request("get", "/developerPaid/detailDiff", params);
}
//财务信息审核
export function financeAudit(data) {
  return request("post", "/developerPaid/check", data);
}
