import request from "@/config/serve.js";

// 开发者资料审批列表查询
export function getVerifiedCheckList(params) {
  return request("post", "/developerUser/businessList", params);
}
// 开发者资料审核
export function updateStatusById(params) {
  return request("post", "/developerUser/updateBusinessById", params);
}
// 开发者资料详情
export function getDetailsByIdDiff(params) {
  return request("post", "/developerUser/getDetailsByIdDiff", params);
}
// 实名认证审批-商务详情
export function getCheckDetail(params) {
  return request("get", "/developerUser/checkDetail", params);
}
// 商务审批
export function updateBusinessByUserId(params) {
  return request("post", "/developerUser/updateBusinessByUserId", params);
}
// 判断是否为新用户
export function getIsNewUser(params) {
  return request("get", "/developerUser/newUser", params);
}
