<template>
  <div>
    <h1
      style="font-size: 22px; font-weight: 800; margin: 0 0 20px 0"
      v-if="detailData.id"
    >
      实名认证审核
    </h1>
    <EditDetail
      :detailData="detailData"
      :imgList="imgList"
      v-if="detailData.id"
      :isRealName="true"
    ></EditDetail>
    <el-form
      :model="formData"
      :rules="rules"
      ref="formRef"
      label-width="240px"
      label-suffix="："
    >
      <el-form-item label="实名认证审核结果" prop="status" v-if="detailData.id">
        <el-radio-group
          v-model="formData.status"
          @change="$refs.formRef.clearValidate()"
        >
          <el-radio :label="3">通过</el-radio>
          <el-radio :label="4">不通过</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="detailData.id"
        label="审核意见"
        prop="remark"
        :rules="[
          { required: formData.status == 4, message: '请输入实名认证审核意见' },
        ]"
      >
        <el-input
          type="textarea"
          :autosize="{ minRows: 6, maxRows: 6 }"
          show-word-limit
          maxlength="100"
          v-model="formData.remark"
          suffix-icon="el-icon-date"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <!-- <div class="disfr jc ac">
      <el-button size="small" type="primary" @click="handleSubmitAudit"
        >提交</el-button
      >
    </div> -->
      <h1
        style="font-size: 22px; font-weight: 800; margin: 30px 0"
        v-if="financeInfoDetail.id"
      >
        财务信息审核
      </h1>
      <Detail
        :detailData="financeInfoDetail"
        v-if="financeInfoDetail.id"
      ></Detail>
      <el-form-item
        label="财务资料审核结果"
        prop="status1"
        v-if="financeInfoDetail.id"
      >
        <el-radio-group
          v-model="formData.status1"
          @change="$refs.formRef.clearValidate()"
        >
          <el-radio :label="2">通过</el-radio>
          <el-radio :label="3">不通过</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="financeInfoDetail.id"
        label="审核意见"
        prop="remark1"
        :rules="[
          {
            required: formData.status1 == 3,
            message: '请输入财务资料审核意见',
          },
        ]"
      >
        <el-input
          type="textarea"
          :autosize="{ minRows: 6, maxRows: 6 }"
          show-word-limit
          maxlength="100"
          v-model="formData.remark1"
          suffix-icon="el-icon-date"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <div class="disfr jc ac">
        <el-button size="small" type="primary" @click="handleSubmitAudit"
          >提交</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import {
  // getVerifiedCheckList,
  // updateStatusById,
  getDetailsByIdDiff,
} from "./indexServe.js";
import EditDetail from "../developerList/Detail.vue";
import Detail from "../financeInfoCheck/Detail.vue";
export default {
  components: { EditDetail, Detail },
  props: {
    checkData: {
      type: Object,
      default() {
        return {};
      },
    },
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
    financeInfoDetail: {
      type: Object,
      default() {
        return {};
      },
    },
    imgList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      // imgList:[],
      // detailData:{},
      formData: { status: "", remark: "", status1: "", remark1: "" },
      userId: "",
      rules: {
        status: [
          {
            required: true,
            message: "请选择实名认证审核结果",
            trigger: "blur",
          },
        ],
        status1: [
          {
            required: true,
            message: "请选择财务资料审核结果",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    init(data) {
      this.formData = {
        status: 3,
        remark: "",
        status1: 2,
        remark1: "",
      };
      this.userId = data.userId;
    },
    handleSubmitAudit() {
      const data = {
        ...this.formData,
      };
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          let obj = { userIdentityForm: {}, paidForm: {} };
          if (this.financeInfoDetail.id) {
            obj.paidForm.status = this.formData.status1;
            obj.paidForm.remark = this.formData.remark1;
            obj.paidForm.id = this.financeInfoDetail.id;
          }
          if (this.detailData.id) {
            obj.userIdentityForm.status = this.formData.status;
            obj.userIdentityForm.remark = this.formData.remark;
            obj.userIdentityForm.id = this.detailData.id;
            obj.userIdentityForm.userId = this.userId;
          }
          if (JSON.stringify(obj.userIdentityForm) === "{}") {
            delete obj.userIdentityForm;
          }
          if (JSON.stringify(obj.paidForm) === "{}") {
            delete obj.paidForm;
          }
          this.$emit("submit", obj);
        }
      });
    },
  },
  created() {
    this.init(this.checkData);
  },
  watch: {
    checkData(data) {
      this.init(data);
    },
  },
};
</script>
<style lang="scss" scoped></style>
